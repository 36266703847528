
















import { namespace } from "vuex-class";
import { Component, Vue } from "vue-property-decorator";
import { gsap } from "gsap";

const galleryModule = namespace("gallery");
@Component
export default class Gallery extends Vue {
  @galleryModule.Getter restorationPieces!: Array<string>;

  displayContent = gsap.timeline();
  animate() {
    this.displayContent.play();
  }
  mounted() {
    this.displayContent.from("main .content", {
      duration: 0.5,
      opacity: 0,
    });
    this.animate();
  }
}
